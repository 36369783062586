import axios from 'axios'
import type { NextPage } from 'next'
import { useRouter } from 'next/router'
import { useContext, useEffect, useState } from 'react'
import PuffLoader from 'react-spinners/PuffLoader'
import { Loader, ImageKit } from '../components'
import { AuthContext } from '../context/auth'
import { AwsCognito } from '../utils/auth'
import { useForm } from 'react-hook-form'
import { NextSeo } from 'next-seo'
import { Mixpanel } from '@utils'
import Link from 'next/link'
import { useLocalStorage } from '@hooks'

interface LoginForm {
  email: string
}
interface CodeForm {
  code: string
}
const Login: NextPage = () => {
  const router = useRouter()
  const {
    register: registerLogin,
    handleSubmit: handleLoginSubmit,
    setError: setLoginErrors,
    watch: watchLoginForm,
    getValues,
    reset,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      email: router.query.email,
    },
  })
  const {
    register: registerCode,
    handleSubmit: handleCodeSubmit,
    setError: setCodeErrors,
    reset: resetCode,
    formState: { errors: codeErrors, isSubmitting: codeIsSubmitting },
  } = useForm({
    defaultValues: {
      code: '',
    },
  })

  const authContext = useContext(AuthContext)
  const [authChecking, setAuthChecking] = useState(true)
  const [authResult, setAuthResult] = useState<any>(null)
  const [localStorageRedirectAfterLogin, setLocalStorageRedirectAfterLogin] =
    useLocalStorage<string>('mx-redirect-after-login', '')

  const onAuthenticationComplete = () => {
    const redirectPath = localStorageRedirectAfterLogin || '/invest/companies'
    setLocalStorageRedirectAfterLogin('')
    router.push(redirectPath)
  }

  const submitForm = async (data: LoginForm) => {
    const email = data.email.toLowerCase()
    await axios
      .get(process.env.NEXT_PUBLIC_API_URL + `/users/exist?email=${email}`, {
        headers: {
          securityKey: process.env.NEXT_PUBLIC_USER_EXIST_HEADER_KEY!,
        },
      })
      .then(async response => {
        const { data } = response
        if (!data) {
          setLoginErrors('email', {
            type: 'custom',
            message: 'Your account does not exists.',
          })
        } else {
          try {
            const result = await AwsCognito.signIn(email)
            if (result) {
              localStorage.setItem('username', result.username)
              localStorage.setItem('session', result.Session)
              setAuthResult(result)
            } else {
              const signUpResult = await AwsCognito.signUpAndSignIn(email)
              if (signUpResult) {
                localStorage.setItem('username', signUpResult.username)
                localStorage.setItem('session', signUpResult.Session)
                setAuthResult(signUpResult)
              }
            }
          } catch (err: any) {
            if (err && err?.name) {
              switch (err.name) {
                default:
                  setLoginErrors('email', {
                    type: 'custom',
                    message: 'Something went wrong',
                  })
              }
            }
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const handleConfirmation = async (data: CodeForm) => {
    const { code } = data
    try {
      const result = await AwsCognito.confirmSignIn(authResult, code)

      if (result) {
        const session = await AwsCognito.currentSession()
        if (!session) throw 'session empty'
        authContext.setUserSession(session)

        const user: any = await axios.get(
          process.env.NEXT_PUBLIC_API_URL + '/me',
        )

        const currentProfile = user?.data?.profiles[0]
        if (router.query.loginRedirect) {
          onAuthenticationComplete()
        } else if (!user.data.isDeferredOnboarding) {
          if (
            !user.data.firstName ||
            !user.data.lastName ||
            !currentProfile.profileType ||
            ((currentProfile.profileType === 'individual' ||
              currentProfile.profileType === 'researching' ||
              currentProfile.profileType === 'institutional' ||
              currentProfile.profileType === 'family') &&
              (!currentProfile.relevantInvestmentExperience ||
                !currentProfile.willingnessToAcceptRisk ||
                !currentProfile.targetSizePerInvestment ||
                !currentProfile.networth)) ||
            ((currentProfile.profileType === 'individual' ||
              currentProfile.profileType === 'researching') &&
              !currentProfile.accreditationStatus) ||
            currentProfile.profileType === 'institutional' ||
            currentProfile.profileType === 'family' ||
            (currentProfile.profileType === 'shareholder' &&
              !currentProfile.shareHoldings)
          ) {
            router.push('/on-boarding')
          } else {
            Mixpanel.identify()
            Mixpanel.people.set({
              first_name: user?.data?.firstName,
              last_name: user?.data?.lastName,
              $email: watchLoginForm('email'),
            })
            onAuthenticationComplete()
          }
        } else {
          Mixpanel.identify()
          Mixpanel.people.set({
            first_name: user?.data?.firstName,
            last_name: user?.data?.lastName,
            $email: watchLoginForm('email'),
          })
          onAuthenticationComplete()
        }
      } else {
        setCodeErrors('code', {
          type: 'custom',
          message: 'Code is invalid or expired',
        })
      }
    } catch (err) {
      setCodeErrors('code', {
        type: 'custom',
        message: 'Code is invalid or expired',
      })
    }
  }

  useEffect(() => {
    Mixpanel.identify()
    Mixpanel.track('Visit-Log-In')
  }, [])

  useEffect(() => {
    if (authContext.session) {
      router.push('/invest/companies')
    }
    setAuthChecking(authContext.session !== null)
  }, [authContext.session])

  useEffect(() => {
    if (router.query.email) {
      router.replace('/login')
    }
    if (router.isReady && router.query.loginRedirect) {
      setLocalStorageRedirectAfterLogin(router.query.loginRedirect as string)
    }
  }, [router])

  return (
    <>
      <NextSeo
        title="Login"
        description="MarketX Ventures is a one-stop shop for over 300 family offices, funds, and
        high net worth individuals to access high growth investment
        opportunities."
        canonical="https://www.marketxventures.com/login"
      />
      {authChecking ? (
        <div className="h-screen">
          <Loader />
        </div>
      ) : (
        <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
          <>
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <div className="relative mx-auto h-12 w-auto">
                <Link href="/">
                  <ImageKit
                    src="/images/marketx-logo-purple.webp"
                    alt="Workflow"
                    fill
                    style={{ objectFit: 'contain' }}
                  />
                </Link>
              </div>
              <h1 className="mb-2 mt-6 text-center text-xl font-semibold text-gray-900">
                Log in to your account
              </h1>
            </div>

            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <div className="bg-white px-4 sm:rounded-lg sm:px-10">
                {isSubmitSuccessful && !errors.email ? (
                  <div className="flex w-full flex-col items-center justify-center">
                    <div className="w-full">
                      <p className="mb-2 mt-6 text-center text-xl font-semibold text-primary">
                        Check your email!
                      </p>
                      <p className="text-md text-center font-semibold text-themeBlack md:text-sm">
                        We just sent you an email at your{' '}
                        {watchLoginForm('email')} email account with a login
                        code and login instructions
                      </p>
                      <p className="mt-6 text-center text-sm  text-themeBlack ">
                        If you don&apos;t received an email message from us
                        shortly, please check your spam folder or contact our{' '}
                        <a
                          className="text-link"
                          href="mailto:business@marketxventures.com">
                          support team
                        </a>
                        .
                      </p>

                      <div className="mt-2">
                        <form
                          id="enter-security-code-form"
                          onSubmit={handleCodeSubmit(handleConfirmation)}>
                          <input
                            type="number"
                            className={`block w-full rounded-md shadow-sm focus:border-primary focus:ring-primary sm:text-sm ${
                              codeErrors.code
                                ? 'border-red-300'
                                : 'border-gray-300'
                            }`}
                            placeholder="Enter Code Here"
                            {...registerCode('code', {
                              required: 'Required.',
                            })}
                          />
                          {codeErrors.code && (
                            <p className="mt-1 text-xs text-red-500">
                              {codeErrors.code.message}
                            </p>
                          )}
                          <button
                            type="submit"
                            className="mt-4 flex w-full justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-light focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2">
                            {!codeIsSubmitting ? (
                              'Verify Code'
                            ) : (
                              <PuffLoader size={24} color={'white'} />
                            )}
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              reset()
                              resetCode()
                            }}
                            disabled={codeIsSubmitting}
                            className={
                              'mt-4 flex w-full justify-center rounded-md border border-transparent bg-themeBlack px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-themeBlack-light focus:outline-none focus:ring-2 focus:ring-themeBlack focus:ring-offset-2'
                            }>
                            Back to Login
                          </button>
                        </form>
                        <p className="mt-6 text-center text-xs  text-themeBlack ">
                          By login into your MarketX Ventures account and using
                          MarketX Ventures services, you acknowledge and accept
                          MarketX Ventures&apos;{' '}
                          <a
                            rel="noreferrer"
                            className="text-link"
                            href="https://docs.google.com/document/d/e/2PACX-1vRCqfJQoKGhi7SP9xk9H5gh0ypxp0RHRC4PbYn-aLYK8x6NdkMh_CNcXdUs86pHuwkH2M548ejrgtVd/pub"
                            target="_blank">
                            Terms of Use
                          </a>
                          ,{' '}
                          <a
                            rel="noreferrer"
                            className="text-link"
                            href="https://docs.google.com/document/u/1/d/e/2PACX-1vRn7Fe07NFLLy6eo84S0Y7N0eBbgg3n8DPnzFaRlDfwuHmZriSSj0kThBEiEz8UWL_7yMvlPH248TMf/pub"
                            target="_blank">
                            Privacy Policy
                          </a>{' '}
                          and
                          <a
                            href={'/disclosure'}
                            rel="noreferrer"
                            target="_blank"
                            className="ml-1 text-link">
                            Disclosures
                          </a>
                          .
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="mt-6 py-8">
                    <form
                      id="login-form"
                      /* @ts-ignore */
                      onSubmit={handleLoginSubmit(submitForm)}>
                      <div>
                        <label
                          htmlFor="email"
                          className="block text-base font-medium text-gray-700">
                          Email
                        </label>
                        <input
                          className={`block w-full rounded-md shadow-sm focus:border-primary focus:ring-primary sm:text-sm ${
                            errors.email ? 'border-red-300' : 'border-gray-300'
                          }`}
                          type="email"
                          {...registerLogin('email', {
                            required: 'Email is required to login.',
                          })}
                        />
                        {errors.email && (
                          <p className="mt-1 text-xs text-red-500">
                            {errors.email.message}
                          </p>
                        )}
                      </div>

                      <div>
                        <button
                          type="submit"
                          className="mt-6 flex w-full justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-light focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                          disabled={isSubmitting}>
                          {!isSubmitting ? (
                            'Send Login Code'
                          ) : (
                            <PuffLoader size={24} color={'white'} />
                          )}
                        </button>
                      </div>
                    </form>
                    <p className="mt-6 text-center text-sm  text-themeBlack ">
                      Don&apos;t have an account?
                      <button
                        onClick={() => {
                          const emailValue = getValues('email') as string
                          if (emailValue) {
                            router.push(
                              `/signup?email=${encodeURIComponent(emailValue)}`,
                            )
                          } else {
                            router.push(`/signup`)
                          }
                        }}
                        className="ml-1 text-link">
                        Sign Up
                      </button>
                    </p>
                    <p className="mt-6 text-center text-xs  text-themeBlack ">
                      By login into your MarketX Ventures account and using
                      MarketX Ventures services, you acknowledge and accept
                      MarketX Ventures&apos;{' '}
                      <a
                        rel="noreferrer"
                        className="text-link"
                        href="https://docs.google.com/document/d/e/2PACX-1vRCqfJQoKGhi7SP9xk9H5gh0ypxp0RHRC4PbYn-aLYK8x6NdkMh_CNcXdUs86pHuwkH2M548ejrgtVd/pub"
                        target="_blank">
                        Terms of Use
                      </a>
                      ,{' '}
                      <a
                        rel="noreferrer"
                        className="text-link"
                        href="https://docs.google.com/document/u/1/d/e/2PACX-1vRn7Fe07NFLLy6eo84S0Y7N0eBbgg3n8DPnzFaRlDfwuHmZriSSj0kThBEiEz8UWL_7yMvlPH248TMf/pub"
                        target="_blank">
                        Privacy Policy
                      </a>{' '}
                      and
                      <a
                        href={'/disclosure'}
                        rel="noreferrer"
                        target="_blank"
                        className="ml-1 text-link">
                        Disclosures
                      </a>
                      .
                    </p>
                  </div>
                )}
              </div>
            </div>
          </>
        </div>
      )}
    </>
  )
}

export default Login
